.banner-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide {
    transition: opacity ease-in-out 0.4s;
}

.btn-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btn-slide:focus {
    outline: none;
}

.prev {
    width: 26px;
    height: 24px;
    position: relative;
	background: #FDF039 0% 0% no-repeat;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 4px;
    border-color: transparent;
	opacity: 1;
}
.next {
	width: 26px;
	height: 24px;
	position: relative;
	background: #FDF039 0% 0% no-repeat;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 4px;
    border-color: transparent;
	opacity: 1;
}

.container-dots {
    position: relative;
    display: flex;
    top: 16px;
    margin-bottom: 16px;
}
.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 5px;
    background: #C3C3C3 0% 0% no-repeat padding-box;
}
.dot.active {
    background: #FFF000 !important;
}

@media screen and (max-width: 375px) {
    .banner-container {
        min-height: 180px;
        background: black;
    }
}